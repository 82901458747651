html {
  background-color: #251640;
  overscroll-behavior-y: none;
  overflow: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 34px;
  background-color: #251640;
  margin: 0;
  padding: 0;
  overflow: auto;
  touch-action: none;
}