p {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 18px;
}

.container {
  text-align: center;
  color: white;
  position: relative;
  background: linear-gradient(
    180deg, 
    #000000 5%, 
    #160E2D 30%, 
    #251640 67%
  );
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: hidden auto;
}

.blur {
  position: absolute;
  z-index: -1;
  filter: blur(10px);
}

.modal {
  text-align: center;
  color: white;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 40px;
  overflow: hidden;
}

.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('./assets/flash_background.png') no-repeat center center fixed;
  background-size: cover;
  mix-blend-mode: overlay;
  opacity: 0.7;
  z-index: 0;
}

.title {
  font-weight: 900;
  font-size: 47px;
  color: #FF7A00;
  margin: 0;
  z-index: 1;
}

.subtitle {
  color: white;
  font-weight: 900;
  font-size: 16px;
  z-index: 1;
}

.image-container {
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.main-image {
  width: 100%;
  max-width: 205px;
}

.task-cointainer {
  width: 100%;
  border: 2px solid #D9D9D9;
  border-radius: 20px;
  z-index: 1;
  background-color: #160D26;
}

.task-cointainer h3 {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 15px;
  margin: 5px 10px;
}

.task-cointainer p {
  margin: 0 10px 10px;
}

.subscribe-container {
  border-radius: 15px;
  background: 
    /* Diamond Gradient */
    radial-gradient(rgba(255, 255, 255, 0.5) 0%, transparent 70%),
    /* Solid Color with 80% Opacity */
    rgba(142, 98, 223, 0.8);
  background-size: cover;
  width: 100%;
  z-index: 1;
  margin-top: 25px;
  margin-bottom: 40px;
}

.subscribe-container h2 {
  color: white;
  font-size: 24px;
  font-weight: 900;
}

.subscribe-container p.hint {
  font-size: 12px;
  margin: 0px 17px 20px;
}

.subscribe-container button {
  font-size: 10px;
  min-width: 125px;
}

button {
  color: white;
  border-radius: 10px;
  border: none;
  font-size: 12px;
  font-weight: 700;
  padding: 9px 10px;
  min-width: 137px;
  margin: 5px;
}

.orange-button {
  background-color: #FF7A00;
  
}

.marging-bottom {
  margin-bottom: 20px;
}

.blue-button {
  background-color: #00B2FF;
}

.white-container {
  background-color: white;
  border-radius: 15px;
  margin: 15px;
  color: #2B1A4B;
  padding: 10px;
}

.white-container p {
  color: #2B1A4B;
  margin-top: 0;
  font-weight: 500;
}

.main-container {
  flex: 1;
  z-index: 1;
}

#congratulation-image {
  position: relative;
  margin-bottom: -25px;
}

#congratulation-image::before {
  content: '';
  position: absolute;
  border-radius: 15px;
  background: 
    /* Diamond Gradient */
    radial-gradient(rgba(255, 255, 255, 0.5) 0%, transparent 70%),
    rgba(142, 98, 223, 1);
  background-size: cover;
  width: 100%;
  height: 400px;
  z-index: -1;
  left: 0px;
  top: 100px;
}

#congratulation-image::after {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  background: url('./assets/celebrate.png') no-repeat center;
  z-index: 0;
  width: 100%;
  height: 480px;
  padding: 0 20px;
}

#congratulation-image .main-image {
  max-width: 292px;
  position: relative;
  z-index: 1;
}

.congratulation-container {
  background-color: white;
  border-radius: 15px;
  color: #000000;
  padding: 5px 20px 20px;
}

.congratulation-container h1 {
  font-size: 24px;
  font-weight: 700;
}

.congratulation-container p {
  font-size: 16px;
  font-weight: 300;
  color: #000000;
  line-height: 24px;
}

.congratulation-container button {
  color: #000000;
  border: #8E62DF solid 2px;
  border-radius: 15px;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  background-color: white;
  margin: 0;
}

.points-container {
  background-color: #8E62DF;
  border-radius: 15px;
  width: 100%;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  color: white;
}

.points-container .points-header {
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-bottom: 10px;
}

.points-container .points-left {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.points-container .points-title {
  font-weight: 900;
  font-size: 22px;
}

.points-container .points-level {
  font-weight: 900;
  font-size: 10px;
  line-height: 4px;
}

.points-container .points-right {
  display: flex;
  text-align: right;
  flex-direction: column;
}

.points-container .points-coin {
  width: 30px;
  height: 26px;
  margin-right: 10px;
  margin-bottom: -5px;
}

.points-container .points-value {
  font-weight: 900;
  font-size: 22px;
}

.points-container .points-progress {
  font-weight: 900;
  font-size: 10px;
  line-height: 4px;
}

.points-container .progress-bar {
  background-color: #d3d3d3;
  border-radius: 12px;
  overflow: hidden;
  height: 8px;
  width: 100%;
  padding: 1px;
  box-sizing: border-box;
}

.points-container .progress {
  background: linear-gradient(
    90deg, 
    #928F8B 0%, 
    #FFFFFF 50%, 
    #928F8B 100%
  );
  height: 6px;
  width: 5%; /* Adjust this percentage to represent the progress */
  border-radius: 20px;
}

.invite-friends-container {
  position: relative;
  border-radius: 20px;
  background: linear-gradient(to bottom, #FFB067, #2B1A4B) border-box;
  border: 1px solid transparent;
  margin-top: 12px;
}

.invite-friends-container .content {
  background-color: #2B1A4B;
  border-radius: 20px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.invite-friends-container h3 {
  font-weight: 900;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.invite-friends-container h3 svg {
  margin-left: 4px;
}

.invite-friends-container p {
  font-weight: 400;
  font-size: 12px;
  margin: 0 auto 5px;
}

.invite-friends-container .input-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 15px;
  position: relative;
}

.invite-friends-container .input-container input {
  width: 100%;
  box-sizing: border-box;
  border: transparent;
  border-radius: 2px;
  height: 20px;
  padding: 2px 25px 2px 10px;
}

.invite-friends-container .input-container svg {
  position: absolute;
  top: 11px;
  right: 20px;
  color: black;
  transform: scaleX(-1);
}

.point-history-container {
  flex: 1;
  z-index: 1;
  margin-top: 10px;
}

.point-history-container table {
  background: #160D26;
  color: white;
  border-radius: 20px;
  border: 2px solid #2B1A4B;
  overflow: hidden;
  width: 100%;
  border-spacing: 0;
}

.point-history-container table thead {
  background: linear-gradient(
    83deg, 
    #38233F 3%, 
    #2B1A4B 38%, 
    #000000 100%
  );
  text-align: left;
  
}

.point-history-container table thead tr td {
  font-size: 14px;
  font-weight: 700;
  padding: 5px 20px 1px;
}

.point-history-container table tbody tr td {
  height: 49px;
  box-sizing: border-box;
  border-bottom: 1px solid #2B1A4B;
}

.point-history-container table tbody tr td.username {
  padding: 7px 10px 7px 20px;
}
.point-history-container table tbody tr td.username .username-container {
  text-align: left;
  text-transform: capitalize;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.point-history-container table tbody tr td.username .user-avatar {
  margin-right: 8px;
}
.point-history-container table tbody tr td.points-type {
  font-size: 10px;
  line-height: 15px;
  font-weight: 700;
  text-align: left;
  padding: 0 2px;
  text-transform: capitalize;
}
.point-history-container table tbody tr td.with-padding {
  padding-left: 20px;
}
.point-history-container table tbody tr td.points-count {
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  padding-right: 20px;
}

img.round-avatar {
  margin-right: 8px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-sizing: border-box;
}

.point-history-container tfoot td {
  max-width: 320px;
  width: 100%;
  white-space: nowrap;
}

.dots-pagination-container {
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.dots-pagination-container .dot {
  display: inline-block;
  margin: 0 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #333333;
}

.dots-pagination-container .dot.active {
  background-color: #FF7A00;
}

p.errorMessage {
  color: red;
  margin-bottom: 0;
}

.loader-container {
  position: absolute;
  left: 50%;
  margin-left: -37px;
  z-index: 2;
  top: 400px;
  text-align: center;
}

.loader-container p {
  color: white;
  text-align: center;
  font-weight: 900;
  font-size: 18px;
  margin: 0;
}

.custom-spinner {
  border: 5px solid #ffffff; /* Light grey */
  border-top: 5px solid transparent; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.footer {
  flex: 1;
  padding: 20px 0;
  position: relative;
  z-index: 1;
  margin-bottom: -40px;
}

.footer::before {
  content: '';
  position: absolute;
  top: -20px;
  left: -150px;
  right: -150px;
  height: 100%;
  background-color: #251640;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  z-index: -1;
}

.footer-title {
  font-weight: 700;
  font-size: 32px;
  color: #FF7A00;
  margin: 0;
}

.footer-subtitle {
  margin-top: 0;
  color: #D9D9D9;
  font-weight: 700;
  font-size: 15px;
}

.footer-ton-logo {
  height: 58px;
  width: 150px;
  margin: 0 auto;
}

.footer-ton-logo img {
  width: 100%;
}

.footer-subtitle2 {
  margin-bottom: 0;
  color: white;
  font-weight: 700;
  font-size: 15px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 2rem;
  color: white;
}

.social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #97969a;
}

.social-icons a.instagram {
  height: 35px;
  width: 35px;
}

.social-icons a.instagram:after {
  background: #97969a;
  height: 32px;
  width: 32px;
}

.social-icons a:after{
  content: '';
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 25px;
  width: 25px;
  position: absolute;
  border-radius: 50%;
  background: white;
}

/* remove after 6th of november 12am */
.select-party-container.cast-vote {
  background: linear-gradient(
    180deg,
    #000000 5%,
    #160E2D 30%,
    #251640 67%
  );
}

.select-party-container.cast-vote:before {
  display: none;
}

.select-party-container.cast-vote .select-party-inner-wrap {
  z-index: 99;
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  min-height: 100vh;
}

.select-party-container.cast-vote .select-party {
  margin-bottom: 40px;
  border-radius: 20px;
  border: 2px solid rgb(43, 26, 75);
  background: rgb(22, 13, 38);
}

.select-party-container.cast-vote .select-party-title-wrap {
  padding: 13px 5px 11px 5px;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  background: linear-gradient(136deg, #38233f 2.64%, #2b1a4b 38.28%, #000 100%), #160d26;
}

.select-party-container.cast-vote .select-party-title-wrap p {
  margin: 0;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}


.select-party-container.cast-vote .select-party-content {
  margin-bottom: 21px;
  padding: 21px 27px 0 27px;
  box-sizing: border-box;
}


.select-party-container.cast-vote .select-party-content-bottom {
  padding: 0 10px 23px 10px;
}


.select-party-container.cast-vote .title {
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 10px #8e62df;
}

.select-party-container.cast-vote .subtitle {
  margin: 0 0 4px 0;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ff7a00;
  max-width: unset;
}

.select-party-container.cast-vote .subtitle-second {
  position: relative;
  z-index: 1;
  margin: 0 0 17px 0;
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 0 #2B1A4B, -2px 2px 0 #2B1A4B, -2px -2px 0 #2B1A4B, 2px -2px 0 #2B1A4B;
}

.select-party-container.cast-vote h3:before {
  content: attr(data-content);
  position: absolute;
  color: inherit;
  font-size: inherit;
  text-shadow: 0 0 10px #ffb067;
  z-index: -1;
}

.select-party-container.cast-vote .vote-card-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.select-party-container.cast-vote .vote-card {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent;
  cursor: pointer;
  box-sizing: border-box;
}

.select-party-container.cast-vote .vote-card.selected .image-container {
  transform: scale(1.1);
}

.select-party-container.cast-vote .vote-card.zoom-out .image-container {
  transform: scale(0.9);
  opacity: 0.5;
}


.select-party-container.cast-vote .vote-card.zoom-out .team-card-btn {
  color: #808080;
  background: #803d00;

}

.select-party-container.cast-vote .vote-card h3 {
  margin: 0;
}

.select-party-container.cast-vote .vote-card .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
  height: 120px;
  width: 120px;
  transition: 0.3s ease-out;
}

.select-party-container.cast-vote .vote-card .image-container img {
  display: block;
  height: 100%;
  width: auto;
}

.select-party-container.cast-vote .vote-card .team-card-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  width: 120px;
  height: 42px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100px;
  background: #ff7a00;
  box-sizing: border-box;
}

.select-party-container.cast-vote .select-party-prize-title {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ff7a00;
}

.select-party-container.cast-vote .select-party-prize-points {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 1;
  text-align: center;
  color: #fff;
}
/* remove after 6th of november 12am */


.select-party-container {
  background: linear-gradient(180deg, #160E2D 5%, #0f0a1f 30%, #0a0614 67%);
  box-sizing: border-box;
}

.select-party-container .select-party-inner-wrap {
  z-index: 99;
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
}

.select-party-container h1 {
  margin-bottom: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  text-align: center;
  color: #ff7a00;
}

.select-party-container h2 {
  max-width: 225px;
  width: 100%;
  margin: 0 auto 48px auto;
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  text-shadow: 0 0 10px #8e62df;
}

.select-party-container .team-card-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 64px;
}

.select-party-container .team-card {
  padding: 10px;
  height: 345px;
  max-width: 166px;
  width: 100%;
  border: 1px solid #961818;
  border-radius: 20px;
  box-shadow: 0 0 5px 0 #f00;
  background: linear-gradient(180deg, rgba(239, 36, 36, 0.5) 0%, rgba(132, 22, 22, 0.5) 100%);
  transition: 0.3s ease-out;
  cursor: pointer;
  box-sizing: border-box;
}

.select-party-container .team-card.selected {
  transform: scale(1.1);
}

.select-party-container .team-card.zoom-out {
  transform: scale(0.9);
  opacity: 0.8;
}

.select-party-container .team-card h3 {
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 18px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.select-party-container .team-card .team-card-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 0;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(180deg, #ef2424 0%, #841616 100%);
  transition: all 300ms ease;
  box-sizing: border-box;
}

.select-party-container .team-card.selected .team-card-inner:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 12px;
  background: linear-gradient(0deg, rgb(192, 92, 0), rgb(255, 176, 103));
  z-index: -1;
}

.select-party-container .team-card.blue {
  border: 1px solid #000aff;
  box-shadow: 0 0 5px 0 #000aff;
  background: linear-gradient(180deg, rgba(0, 10, 255, 0.5) 0%, rgba(25, 34, 115, 0.5) 100%);
}

.select-party-container .team-card.blue .team-card-inner {
  background: linear-gradient(180deg, #3345ec 0%, #192273 100%);
}

.select-party-container .team-card h3 {
  margin: 0;
}

.select-party-container .team-card .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 253px;
  width: 130px;
}

.select-party-container .team-card .image-container img {
  display: block;
  height: 100%;
  width: auto;
}

.select-party-container .button-large {
  padding: 16px 24px;
}

.select-party-title-wrap {
  padding: 13px 5px 11px 5px;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  background: linear-gradient(136deg, #38233f 2.64%, #2b1a4b 38.28%, #000 100%), #160d26;
}

.select-party-title-wrap p {
  margin: 0;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}

.button-large {
  position: relative;
  padding: 20px 24px;
  max-width: 345px;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  background: linear-gradient(0deg, #C05C00 0%, #FFB067 100%);
  cursor: pointer;
}

.button-large:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 100px;
  background: linear-gradient(100deg, rgb(192, 92, 0), rgb(255, 176, 103));
  z-index: -1;
}

.button-large:disabled {
  pointer-events: none;
  color: #2c1500;
  text-shadow: unset;
  border: 3px solid #321d09;
  background: #854000;
}

.button-large:disabled:before {
  display: none;
}

.game-container {
  text-align: center;
  color: white;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 0;
  overflow: hidden auto;
  background: linear-gradient(180deg, #000 12.25%, #160e2d 47.37%, #251640 71%, #0f0a1e 82.2%);
}

.game-container-inner {
  position: relative;
  z-index: 1;
  padding: 24px 20px 40px 20px;
  overflow: hidden auto;
}

.game-container .board {
  max-width: 345px;
  width: 100%;
  margin: 0 auto 32px auto;
  border: 2px solid rgb(43, 26, 75);
  border-radius: 20px;
  background: #160d26;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
}

.game-container-inner.started .board .board-title-main {
  display: none;
}

.game-container-inner.started .board .board-title-active {
  display: flex;
}

.game-container-inner.started .board .board-inner {
  background: linear-gradient(135.87deg, rgb(56, 35, 63) 2.826%,rgb(43, 26, 75) 37.34%,rgb(255, 122, 0) 97.104%);
}

.game-container .board .board-inner {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: linear-gradient(135.87deg, rgb(56, 35, 63) 2.826%,rgb(43, 26, 75) 37.34%,rgb(0, 0, 0) 97.104%);
}

.game-container .board .board-title {
  padding: 16px 10px 13px 10px;
  height: 56px;
  border-bottom: 1px solid rgb(22, 14, 45);
  box-sizing: border-box;
  text-transform: uppercase;
}

.game-container .board .board-title-active {
  display: none;
  padding: 0 14px;
  justify-content: space-between;
}

.game-container .board .board-title-active .points {
  display: flex;
  align-items: center;
}

.game-container .board .board-title-active .points span {
  display: inline-block;
  margin-left: 13px;
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 1;
  color: #fff;
  text-shadow: -2px 0 #2B1A4B, 0 2px #2B1A4B, 2px 0 #2B1A4B, 0 -2px #2B1A4B;
}

.game-container .board h2 {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 900;
  line-height: 27px;
  color: #fff;
}

.game-container .board-info {
  display: flex;
  font-family: Poppins, sans-serif;
}

.game-container .board-info .board-info-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 23px 14px 24px;
  width: 50%;
}

.game-container .board-info .board-info-column:first-child {
  border-right: 1px solid #160e2d;
}

.game-container .board-info .board-info-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.game-container .board-info .board-info-value {
  font-size: 12px;
  font-weight: 900;
  line-height: 18px;
}

.game-container .board-teams-coins {
  display: flex;
}

.game-container .board-team-column {
  padding: 6px 6px 6px 23px;
  width: 50%;
  text-align: left;
}

.game-container .board-team-column:first-child {
  border-right: 1px solid #2b1a4b;
}

.game-container .board-team {
  position: relative;
  display: inline-block;
  margin: 0;
  padding-left: 28px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}

.game-container .board-team span {
  font-weight: 700;
}

.game-container .board-team:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #000aff;
}

.game-container .board-team.red:before {
  background: #ef2424
}

.game-container .politics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.game-container .politics-grid .cell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93px;
}

.game-container .politics-grid .cell:before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 80px;
  height: 32px;
  background: url('./assets/hole.png') no-repeat;
}

.game-container-inner:not(.started) .politics-grid .politic {
  border-radius: unset;
}

.game-container-inner:not(.started) .politics-grid .politic:before {
  bottom: 2px;
  border: 1px solid;
  border-radius: 50%;
}

.game-container-inner:not(.started) .politics-grid .politic.blue:before {
  border-color: #000aff;
}

.game-container-inner:not(.started) .politics-grid .politic.red:before {
  border-color: #ef2424;
}

.game-container-inner:not(.started) .politics-grid .cell:nth-child(1) .politic-head img {
  width: 118px;
}

.game-container-inner:not(.started) .politics-grid .cell:nth-child(4) .politic-head {
  width: 72px;
}

.game-container-inner:not(.started) .politics-grid .cell:nth-child(4) .politic-head img {
  width: 121px;
}

.game-container-inner:not(.started) .politics-grid .cell:nth-child(6) .politic-head img {
  width: 115px;
}

.game-container-inner:not(.started) .politics-grid .politic-head {
  transform: translateY(8px);
}

.game-container .politics-grid .politic {
  flex-direction: column;
  border-radius: 50%;
  overflow: hidden;
}

.game-container .politics-grid .politic.hide {
  pointer-events: none;
}

.game-container .politics-grid .politic-notifications {
  z-index: 2;
  position: absolute;
  top: 5px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 100px;
  backdrop-filter: blur(20px);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  pointer-events: none;
  user-select: none;
  outline: 0;
  animation: fadeInUp 0.2s ease-out forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}

.game-container .politics-grid .politic-notifications span {
  margin-left: 5px;
  font-weight: 900;
  font-size: 18px;
  text-align: right;
  color: #fff;
}

.game-container .politics-grid .politic-head {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  width: 70px;
  margin: 0 auto;
  border-radius: 25%;
  overflow: hidden;
  user-select: none;
  outline: 0;
  transform: translateY(10px);
  transition: opacity 0.5s ease;
}

.game-container .politics-grid .politic.hide .politic-head {
  opacity: 0;
}

.game-container .politics-grid .politic-head img {
  width: 120px;
}

@keyframes moveY {
  0% {
    transform: translateY(85px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(85px);
  }
}

.game-container .button-large {
  margin-top: 40px;
  max-width: 191px;
}

.game-container .button-large.button-timer {
  background: #160d26;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  cursor: default;
}

.game-container .button-large.button-timer span {
  color: #ff7a00;
}

.game-container .button-large.button-sand {
  position: relative;
  padding: 12px 5px 12px 25px;
  font-weight: 700;
  font-size: 29px;
  text-shadow: 1px 2px 0 #000, -2px 2px 0 #000, -2px -1px 0 #000, 1px -1px 0 #000;
  background: linear-gradient(180deg, #653db1 0%, #2b1a4b 100%);
  cursor: default;
}

.game-container .button-large.button-sand:after {
  content: '';
  position: absolute;
  top: -30px;
  left: -15px;
  width: 100px;
  height: 104px;
  background: url('./assets/timer.png') no-repeat;
  background-size: 112px;
}

.game-container .button-large.button-sand.times-up {
  max-width: 264px;
  font-size: 25px;
  letter-spacing: 0;
  background: #8e62df;
  cursor: default;
}

.tabs-container {
  padding-bottom: 70px !important;
  justify-content: space-between;
  overflow: hidden auto;
  touch-action: none;
  box-sizing: border-box;
}

.tabs-router {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  min-height: 55px;
}

.tabs-router.white {
  margin-bottom: 0;
}

.tabs-router.white a.active {
  border-color: transparent;
}

.tabs-router.white a.active svg {
  stroke: white;
}

.tabs-router ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #302e2e;
  box-sizing: border-box;
}

.tabs-router ul li {
  width: 50%;
}

.tabs-router a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #7a7a7a;
  text-decoration: unset;
}

.tabs-router a.active {
  color: #fff;
}

.tabs-router a.active svg {
  stroke: #FF7A00;
}

.tabs-router a.active {
  border-bottom: 3px solid #ff7a00;
}

.tabs-router a svg {
  margin-right: 10px;
  stroke: #7A7A7A;
}

.tabs-router.bottom-menu {
  z-index: 1;
  position: fixed;
  bottom: 0;
  margin-bottom: 0;
  max-width: unset;
  background: #000;
}

.tabs-router.bottom-menu ul {
  padding: 0 23px;
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  border-bottom: unset;
  border-top: 1px solid #302e2e;
}

.tabs-router.bottom-menu li {
  width: calc(100% / 5);
}

.tabs-router.bottom-menu a {
  flex-direction: column;
  border-top: 3px solid transparent;
  font-size: 9px;
  line-height: 22px;
}

.tabs-router.bottom-menu a.active {
  font-weight: 600;
  border-bottom: unset;
  border-top: 3px solid #ff7a00;
}

.tabs-router.bottom-menu a .svg-wrap {
  display: flex;
  justify-content: center;
  justify-items: center;
  height: 24px;
  width: 24px;
}

.tabs-router.bottom-menu a svg {
  margin-right: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(30px);
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  overflow-y: auto;
  transition: opacity 0.3s;
  z-index: 999;
  box-sizing: border-box;
}

.modal.vote-modal {
  background: linear-gradient(
    180deg,
    #000000 5%,
    #160E2D 30%,
    #251640 67%
  );
}

.modal.vote-modal .modal-wrapper {
  display: block;
  min-height: 100vh;
}

.modal.vote-modal .modal-content {
  height: 100vh;
  margin-right: auto;
  margin-left: auto;
  border-radius: unset;
  border: none;
  background: unset;
}

.modal.vote-modal .modal-content h2 {
  max-width: unset;
}

.modal.vote-modal .vote-modal-close {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0 auto;
  padding: 0;
  height: 48px;
  width: 48px;
  min-width: unset;
  cursor: pointer;
  background: unset;
}

.modal.vote-modal .vote-modal-close img {
  height: 100%;
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.modal-content {
  position: relative;
  margin: 20px;
  width: 100%;
  max-width: 345px;
  border-radius: 20px;
  border: 2px solid rgb(43, 26, 75);
  background: linear-gradient(136deg, #38233f 2.64%, #2b1a4b 38.28%, #000 100%), #160d26;
  padding: 43px 20px 37px 20px;
  transform: translateY(-50px);
  transition: opacity 0.3s, transform 0.3s;
  box-sizing: border-box;
}

.modal-content h2 {
  margin: 0;
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  color: #fff;
  text-shadow: 0 0 10px #8e62df;
}

.modal-content .points {
  display: flex;
  justify-content: center;
  margin: 26px 0;
}

.modal-content .points span {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 38px;
  line-height: 1;
  color: #fff;
  text-shadow:
    3px 3px 0 #2B1A4B,
    -3px 3px 0 #2B1A4B,
    -2px -3px 0 #2B1A4B,
    2px -3px 0 #2B1A4B;
}

.modal-content .points span:before {
  content: attr(data-content);
  position: absolute;
  color: inherit;
  font-weight: 900;
  font-size: 38px;
  text-shadow: 0 0 23px #ffb067;
  z-index: -1;
}

.modal-content p {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #fff;
}

.modal-content .button-large {
  margin-top: 25px;
  max-width: 279px;
}

.scores-container {
  text-align: center;
  color: white;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 0;
  background: linear-gradient(180deg, #000 12.25%, #160e2d 47.37%, #251640 71%);
}

.scores-inner {
  padding-top: 24px;
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
  overflow: hidden auto;
}

.scores-inner .users-list .loader-container {
  position: static;
  top: auto;
  right: 0;
  left: 0;
  margin: auto;
  padding: 47px 0 27px 0;
}

.scores-inner .users-list .loader-container p {
  display: none;
}

.trigger {
  height: 20px;
  background: transparent;
}

.scores-main {
  position: relative;
  z-index: 1;
  padding: 0 24px 17px 24px;
}

.my-info .user-item {
  padding: 14px 25px 14px 14px;
  border-radius: 20px;
  background: linear-gradient(136deg, #38233f 2.64%, #2b1a4b 38.28%, #ff7a00 100%);
}

.my-info .user-item .user-points span {
  margin-left: 21px;
}

.top-users {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 0 60px 0;
  list-style: none;
}

.top-users .top-user-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 74px;
  padding: 0 25px;
  box-sizing: border-box;
}

.top-users .top-user-item .user-title {
  margin-bottom: 4px;
  text-align: center;
}

.top-users .top-user-item .user-points {
  margin-bottom: 10px;
  font-size: 14px;
  text-shadow: 1px 1px 0 #2B1A4B, -1px 1px 0 #2B1A4B, -1px -1px 0 #2B1A4B, 1px -1px 0 #2B1A4B;
}

.top-users .top-user-item .user-points span:before {
  content: attr(data-content);
  position: absolute;
  color: inherit;
  font-size: 14px;
  text-shadow: 0 0 10px #ffb067;
  z-index: -1;
}

.top-users .top-user-item .avatar-wrap {
  position: relative;
  user-select: none;
}

.top-users .top-user-item .user-rank {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -56px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.top-users .top-user-item .user-rank span {
  position: absolute;
  top: 13px;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  font-size: 20px;
  color: #000;
}

.top-users .top-user-item.first-place {
  position: relative;
  margin-top: 0;
  padding-top: 45px;
  width: 137px;
}

.top-users .top-user-item.first-place:before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 18px;
  width: 137px;
  height: 246px;
  background: url('./assets/first-place-flag.png') no-repeat;
}

.top-users .top-user-item.first-place .user-avatar {
  border-color: transparent !important;
  background: linear-gradient(
    to right,
    rgb(255, 157, 67),
    rgb(255, 255, 255),
    rgb(255, 176, 103),
    rgb(255, 122, 0)
  );
}

.top-users .top-user-item.first-place .user-points {
  margin-bottom: 5px;
}

.top-users .top-user-item.first-place .user-rank {
  bottom: -54px;
}

.users-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.users-list .user-title {
  color: #fff;
}

.user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 39px 16px 24px;
  margin-bottom: 5px;
  background: #0f0a1e;
}

.user-item .user-item-left {
  display: flex;
  align-items: center;
}

.user-avatar {
  position: relative;
  border-radius: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  user-select: none;
}

.user-avatar img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.user-avatar .user-avatar-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: normal;
  text-transform: uppercase;
  border-radius: 50%;
  overflow: hidden;
}

.user-avatar .user-avatar-placeholder span {
  opacity: .9;
  filter: saturate(0.8);
}

.user-item .user-item-info {
  margin-left: 15px;
}

.user-title {
  margin: 0 0 2px 0;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  color: #ff7a00;
}

.user-rank {
  margin: 0;
  text-align: left;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
}

.user-points {
  position: relative;
  font-weight: 900;
  font-size: 14px;
  text-align: center;
  color: #fff;
  text-shadow: 0 0 10px #8e62df;
}

.user-item .user-points {
  border-left: 1px solid #2d1b4b;
}

.user-item .user-points span {
  margin-left: 31px;
}

.coming-soon {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  background-color: #251640;
}

.coming-soon .coming-soon-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 23px auto;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background: #2b1a4b;
}

.coming-soon .coming-soon-icon svg {
  fill: none;
  stroke: #916e9c;
}

.coming-soon h1 {
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  color: #fff;
  text-shadow: 0 0 10px #8e62df;
}

.coming-soon p {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #916e9c;
}

.earn-points-container {
  padding: 26px 26px 45px 26px;
  overflow: hidden auto;
}

.earn-points-container ul {
  padding: 0;
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
  list-style: none;
}

.earn-points-item {
  position: relative;
  margin-bottom: 17px;
  border-radius: 20px;
  border: 2px solid rgb(43, 26, 75);
  background: rgb(22, 13, 38);
}

.earn-points-item-inactive {
  position: absolute;
  background-color: #0F0A1E80;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.earn-points-item:last-of-type {
  margin-bottom: 0;
}

.earn-points-item.done .earn-points-bar {
  background: linear-gradient(136deg, #38233f 2.64%, #2b1a4b 38.28%, #000 100%), #160d26;
}

.earn-points-item .earn-points-bar {
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  height: 40px;
  width: 100%;
  background: linear-gradient(
    135.87deg,
    rgb(56, 35, 63) 2.841%,
    rgb(43, 26, 75) 37.264%,
    rgb(255, 122, 0) 96.87%);
}

.earn-points-item .earn-points-bar img {
  margin-top: 21px;
}

.earn-points-item .earn-points-content {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px 10px 26px 10px;
  box-sizing: border-box;
}

.earn-points-item h2 {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 22px;
  line-height: 109%;
  text-align: center;
  color: #fff;
}

.earn-points-item .earn-points-title {
  margin: 0 0 6px 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ff7a00;
}

.earn-points-item .earn-points-value {
  position: relative;
  margin-bottom: 14px;
  font-weight: 900;
  font-size: 24px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 0 #2B1A4B, -2px 2px 0 #2B1A4B, -2px -2px 0 #2B1A4B, 2px -2px 0 #2B1A4B;
}

.earn-points-item .earn-points-value:before {
  content: attr(data-content);
  position: absolute;
  color: inherit;
  font-size: inherit;
  text-shadow: 0 0 10px #ffb067;
  z-index: -1;
}

.earn-points-item .button-wrap {
  width: 100%;
}

.earn-points-item .button-large {
  margin: 3px;
  padding: 14px 5px;
  min-width: auto;
  max-width: 120px;
  font-size: 14px;
}

.earn-points-item .button-large.button-pending {
  pointer-events: none;
  background: linear-gradient(180deg, #653db1 0%, #2b1a4b 100%);
}

.earn-points-item .button-large.button-pending:before {
  background: linear-gradient(180deg, #2b1a4b 0%, #653db1 100%);
}

.earn-points-item .button-large.button-done {
  position: relative;
  margin: 0;
  padding-left: 58px;
  max-width: 125px;
  text-align: left;
  pointer-events: none;
  text-shadow: unset;
  border: 3px solid #321d09;
  background: #854000;
}

.earn-points-item .button-large .button-inactive {
  position: relative;
  margin: 0;
  padding-left: 58px;
  max-width: 125px;
  text-align: left;
  pointer-events: none;
  text-shadow: unset;
  border: 3px solid #321d09;
  background: #854000;
}

.earn-points-item .button-large.button-done:before {
  content: '';
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 23px;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  background: #05ff0080;
}

.earn-points-item .button-large.button-done:after {
  content: '';
  z-index: 2;
  position: absolute;
  top: 20px;
  left: 29px;
  width: 11px;
  height: 6px;
  color: #fff;
  border-bottom: solid 1px currentColor;
  border-left: solid 1px currentColor;
  transform: rotate(-45deg);
}

.earn-points-item p {
  margin-top: 23px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #fff;
}

.earn-points-msg {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 260px;
  width: 100%;
  margin: 10px auto 0 auto;
}

.earn-points-msg svg {
  flex-shrink: 0;
  margin: -4px 5px 0 0;
}

.earn-points-msg p {
  margin: 0;
  font-size: 8px;
  line-height: 12px;
  color: #fff;
}

.account-container {
  padding-top: 24px;
  flex-grow: 1;
  overflow: hidden auto;
}

.account-inner {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px 25px 24px;
  box-sizing: border-box;
}

.account-container .account-user {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 8px;
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  color: #fff;
}

.account-container .account-user .user-avatar {
  margin-right: 12px;
}

.account-container .account-info {
  margin: 0 0 7px 0;
  padding: 0;
  list-style: none;
}

.account-container .account-info li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 24px 8px 24px;
  margin-bottom: 15px;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
  border: 1px solid #000;
  border-radius: 15px;
  background: linear-gradient(180deg, #653db1 0%, #2b1a4b 100%);
  box-sizing: border-box;
}

.account-container .account-info li:first-child span {
  font-size: 23px;
}

.account-container .account-info li:last-child {
  margin-bottom: 0;
}

.account-container .account-info li span {
  display: flex;
  align-items: center;
}

.account-container .account-info li span img {
  margin-right: 10px;
}

.account-container .account-info h3 {
  margin: 0 10px 0 0;
  font-size: inherit;
  line-height: 21px;
  text-align: left;
  text-transform: uppercase;
}

.account-footnote {
  margin: 0 0 7px 0;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #fff;
}

.account-stats {
  border: 2px solid rgb(43, 26, 75);
  border-radius: 20px;
  background: rgb(22, 13, 38);
}

.account-stats .account-stats-title {
  display: flex;
  align-items: center;
  padding: 0 22px;
  height: 40px;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  background: linear-gradient(136deg, #38233f 2.64%, #2b1a4b 38.28%, #000 100%), #160d26;
}

.account-stats .account-stats-title h2 {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  line-height: 21px;
}

.account-stats-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

.account-stats-list li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 5px;
  border-top: 1px solid #2b1a4b;
}

.account-stats-list li:first-child,
.account-stats-list li:nth-child(2) {
  border-top: unset;
}

.account-stats-list li:nth-child(odd) {
  border-right: 1px solid #2b1a4b;
}

.account-stats-item-inner {
  display: flex;
  align-items: center;
  max-width: 140px;
  width: 100%;
}

.account-stats-left {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #2b1a4b;
}

.account-stats-right {
  padding-top: 6px;
  text-align: left;
}

.account-stats-right h3 {
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #ff7a00;
}

.account-stats-right span {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  white-space: pre;
}

.button-large.view-points {
  padding: 22px 24px;
  letter-spacing: 0;
  text-shadow: 1px 2px 0 #000, -2px 2px 0 #000, -2px -1px 0 #000, 1px -1px 0 #000;
  background: linear-gradient(180deg, #8e62df 0%,  #653db1 100%);
}

.button-large.view-points:before {
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
}

.election-list {
  position: relative;
  padding: 0;
  margin: 18px auto 14px auto;
  list-style: none;
  max-width: 768px;
  width: 100%;
}

.election-list:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: auto;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.01);
}

.election-list li {
  margin-bottom: 10px;
  border-radius: 20px;
  background: rgb(22, 13, 38);
}

.election-list li:last-child {
  margin-bottom: 0;
}

.election-list .election-title-wrap {
  padding: 13px 5px 11px 5px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background: linear-gradient(136deg, #38233f 2.64%, #2b1a4b 38.28%, #000 100%), #160d26;
}

.election-list .election-title-wrap p {
  font-weight: 900;
  font-size: 14px;
  color: #fff;
}

.election-list .election-content {
  display: flex;
  justify-content: center;
  padding: 15px 23px 18px 17px;
  box-sizing: border-box;
}

.election-list .election-content-left {
  margin-right: 11px;
  height: 114px;
  width: 114px;
}

.election-list .election-content-left img {
  display: inline-block;
  width: 100%;
}

.election-list .election-content-right {
  display: flex;
  flex-direction: column;
}

.election-list .election-candidate {
  margin-bottom: 15px;
}

.election-list .election-candidate h3 {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  color: #ff7a00;
}

.election-list .election-candidate p {
  font-weight: 900;
  font-size: 14px;
  text-align: center;
  color: #fff;
}

.election-list .election-actions-list {
  display: flex;
}

.election-list .election-actions-list button {
  margin: 0 0 5px 0;
  border: 1px solid #fff;
  border-radius: 100px;
  padding: 9px 24px;
  min-width: 80px;
  height: 30px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.election-list .election-actions-list button.green {
  background: #00851d;
}

.election-list .election-actions-list button.red {
  background: #ef2424;
}

.election-list .election-actions-list p,
.election-list .election-actions-list span {
  font-weight: 900;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #fff;
}

.election-list .election-action-wrap {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}

.election-list .election-action-wrap:last-of-type {
  margin-right: 0;
}

.vote-tweet.earn-points-item .earn-points-content {
  padding-bottom: 20px;
}

.vote-tweet.earn-points-item .earn-points-title {
  margin-bottom: 0;
}

.vote-tweet.earn-points-item .earn-points-value {
  margin-bottom: 30px;
}

.vote-tweet.earn-points-item .button-large {
  margin-bottom: 20px;
}

.bet-win-container {
  flex: 1;
  padding: 20px;
  overflow: hidden auto;
}

.bet-win-container:before {
  display: none;
}

.bet-win-container .bet-win-inner-wrap {
  z-index: 99;
  margin: 0 auto;
  max-width: 346px;
  width: 100%;
}

.bet-win-container .account-stats-title {
  justify-content: center;
  text-transform: uppercase;
}

.bet-win-container .account-stats-right span {
  font-size: 12px;
  line-height: 18px;
}

.bet-win {
  z-index: 1;
  position: relative;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 2px solid rgb(43, 26, 75);
  background: rgb(22, 13, 38);
}

.bet-win-content {
  padding: 9px 20px 18px 20px;
  box-sizing: border-box;
}

.bet-win-content.winner {
  background: url('./assets/winner-celebrate.png') no-repeat;
}

.bet-win-content.winner .title {
  color: #fff;
}

.bet-win-content .title {
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #ff7a00;
}

.bet-win-content .points {
  margin: 0 0 10px 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #fff;
}

.bet-win-content .team-card-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.bet-win-content .team-card {
  padding: 9px;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  box-sizing: border-box;
}

.bet-win-content .team-card.no-cursor {
  cursor: default;
}

.bet-win-content .team-card.red {
  border-color: #961818;
  box-shadow: 0 0 5px 0 #f00;
  background: linear-gradient(180deg, rgba(239, 36, 36, 0.5) 0%, rgba(132, 22, 22, 0.5) 100%);
}

.bet-win-content .team-card.blue {
  border-color: #000aff;
  box-shadow: 0 0 5px 0 #000aff;
  background: linear-gradient(180deg, rgba(0, 10, 255, 0.5) 0%, rgba(25, 34, 115, 0.5) 100%);
}

.bet-win-content .team-card .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  height: 120px;
  width: 120px;
  transition: 0.3s ease-out;
}

.bet-win-content .team-card .image-container img {
  display: block;
  height: 100%;
  width: auto;
}

.bet-win-content.winner .team-card h2 {
  color: #fff;
}

.bet-win-content .team-card h2 {
  margin: 0;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #ff7a00;
}

.bet-win-content .team-card span {
  font-weight: 900;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  color: #fff;
}

.bet-win-content .pending-text {
  font-weight: 900;
  font-size: 14px;
  text-align: center;
  color: #ff7a00;
  text-transform: uppercase;
}

.bet-win-content .bet-win-btn.closed {
  pointer-events: none;
  background: linear-gradient(180deg, #ff7a00 0%, rgba(255, 122, 0, 0.74) 100%);
}

.bet-win-content .bet-win-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 12px 24px;
  width: 121px;
  min-width: unset;
  height: 31px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  cursor: pointer;
  box-sizing: border-box;
}

.bet-win-content .bet-win-btn:before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.7);
  z-index: -1;
}

.bet-win-content .bet-win-btn.orange:disabled {
  pointer-events: none;
  background: #160d26;
}

.bet-win-content .bet-win-btn.orange:disabled:before {
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(180deg, #ff9d43 0%, #ff7a00 100%);
}

.bet-win-content .bet-win-btn.orange {
  background: linear-gradient(180deg, #ff9d43 0%, #ff7a00 100%);
}

.bet-win-content .bet-win-btn.purple {
  background: linear-gradient(180deg, #653db1 0%, rgba(101, 61, 177, 0.83) 100%);
}

.seed-ifo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 15px 0;
  overflow-y: auto;
}

.seed-ifo-img {
  width: 100%;
  max-width: 388px;
  height: auto;
}